.mdlHeaderWrap.modal-header {
  position: relative;
  justify-content: center !important;
}

.mdlHeaderWrap.modal-header button.btn-close {
  position: absolute;
  right: 20px;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

@media (max-width: 428px) {
  .mdlHeaderWrap.modal-header {
    justify-content: left !important;
  }

  .show-grid.modal-body .input-group input,
  .show-grid.modal-body select {
    padding-left: 0;
  }

  .modal-footer {
    justify-content: left;
  }
}

body.modal-open .Head-Fade > div {
  display: none;
}

.modal-footer {
  justify-content: center !important;
}

@media (max-width: 575px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}
/*Languahe DropDown :30-Nov-22 Jignesh Sailor*/

/* lang dropdown theme 7 */
.langDbBtnSidebar-7 {
  display: none;
}

.langDbBtnSidebar-7 img {
  height: unset;
}

.langDbBtn-7 .language-dropdown .dropdown-toggle img,
.langDbBtnSidebar-7 .language-dropdown .dropdown-toggle img {
  width: 20px;
  height: 15px;
  margin-right: 5px;
}

.langDbBtn-7 .language-dropdown .dropdown-toggle:hover,
.langDbBtnSidebar-7 .language-dropdown .dropdown-toggle:hover {
    background: #fff;
    border-color: #fff;
    color: #919191;
}

@media (max-width: 767px) {
  .langDbBtnSidebar-7 {
    display: block;
  }

  .langDbBtn-7 {
    display: none;
  }
}

/* lang dropdown theme 8 */
.langDbBtnSidebar-8 {
  display: none;
}

.langDbBtnSidebar-8 img {
  height: unset;
}

.langDbBtn-8 .language-dropdown .dropdown-toggle img,
.langDbBtnSidebar-8 .language-dropdown .dropdown-toggle img {
  width: 20px;
  height: 15px;
  margin-right: 5px;
  opacity: 1;
}

.langDbBtn-8 .language-dropdown .dropdown-toggle,
.langDbBtnSidebar-8 .language-dropdown .dropdown-toggle {
  border-radius: 300px;
  background-color: rgb(135, 28, 60);
  border: 2px solid #fff;
  padding-left: 20px;
  padding-right: 20px;
}

.langDbBtn-8 .language-dropdown .dropdown-toggle:hover,
.langDbBtnSidebar-8 .language-dropdown .dropdown-toggle:hover {
  background: #000;
  color: #fff;
}

@media (max-width: 991px) {
  .header-links .bookNowButton {
    padding: 12px 38px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .langDbBtnSidebar-8 {
    display: block;
  }

  .langDbBtn-8 {
    display: none;
  }

  .langDbBtnSidebar-8 {
    padding-left: 10px;
    margin-top: 10px;
  }

  .langDbBtnSidebar-8 .language-dropdown .dropdown-toggle {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 575px) {
  .header-links .bookNowButton {
    padding: 8px 18px;
    font-size: 10px;
  }
}

/* lang dropdown theme 9 */
.langDbBtnSidebar-9 {
  display: none;
}

.langDbBtnSidebar-9 img {
  height: unset;
}

.langDbBtn-9 .language-dropdown .dropdown-toggle img,
.langDbBtnSidebar-9 .language-dropdown .dropdown-toggle img {
  width: 20px;
  height: 15px;
  margin-right: 5px;
  opacity: 1;
}

.langDbBtn-9 {
  margin-left: 10px;
}

.langDbBtn-9 .language-dropdown .dropdown-toggle,
.langDbBtnSidebar-9 .language-dropdown .dropdown-toggle {
  background-color: #d4af37;
  padding: 13px 20px;
  border: 0;
}

.langDbBtn-9 .language-dropdown .dropdown-toggle:hover,
.langDbBtnSidebar-9 .language-dropdown .dropdown-toggle:hover {
  background: #000;
  color: #fff;
}

@media (max-width: 991px) {
  .langDbBtn-9 .language-dropdown .dropdown-toggle {
    padding: 10px 20px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .langDbBtnSidebar-9 {
    display: block;
  }

  .langDbBtn-9 {
    display: none;
  }

  .langDbBtnSidebar-9 {
    margin-top: 10px;
  }

  .langDbBtnSidebar-9 .language-dropdown .dropdown-toggle {
    padding: 10.5px 30px;
  }
}

@media (max-width: 575px) {
  .header-links .bookNowButton {
    padding: 8px 18px;
    font-size: 10px;
  }
}

/* lang dropdown theme 10 */
.langDbBtnSidebar-10 {
  display: none;
}

.langDbBtnSidebar-10 img {
  height: unset;
}

.langDbBtn-10 .language-dropdown .dropdown-toggle img,
.langDbBtnSidebar-10 .language-dropdown .dropdown-toggle img {
  width: 20px;
  height: 15px;
  margin-right: 5px;
  opacity: 1;
}

.langDbBtn-10 {
  margin-left: 10px;
}

.langDbBtn-10 .language-dropdown .dropdown-toggle,
.langDbBtnSidebar-10 .language-dropdown .dropdown-toggle {
  background-color: #a08033;
  padding: 13px 20px;
  border: 0;
  border-radius: 5px;
}

.langDbBtn-10 .language-dropdown .dropdown-toggle:hover,
.langDbBtnSidebar-10 .language-dropdown .dropdown-toggle:hover {
  background: #000;
  color: #fff;
}

@media (max-width: 991px) {
  .langDbBtn-10 .language-dropdown .dropdown-toggle {
    padding: 10px 20px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .langDbBtnSidebar-10 {
    display: block;
  }

  .langDbBtn-10 {
    display: none;
  }

  .langDbBtnSidebar-10 {
    margin-top: 10px;
  }

  .langDbBtnSidebar-10 .language-dropdown .dropdown-toggle {
    padding: 10.5px 30px;
  }
}

@media (max-width: 575px) {
  .header-links .bookNowButton {
    padding: 8px 18px;
    font-size: 10px;
  }
}

/* lang dropdown theme 11 */
.langDbBtnSidebar-11 {
    display: none;
  }
  
  .langDbBtnSidebar-11 img {
    height: unset;
  }
  
  .langDbBtn-11 .language-dropdown .dropdown-toggle img,
  .langDbBtnSidebar-11 .language-dropdown .dropdown-toggle img {
    width: 20px;
    height: 15px;
    margin-right: 5px;
    opacity: 1;
  }
  
  .langDbBtn-11 {
    margin-left: 10px;
  }
  
  .langDbBtn-11 .language-dropdown .dropdown-toggle,
  .langDbBtnSidebar-11 .language-dropdown .dropdown-toggle {
    background-color: #81B5B5;
    padding: 12px 20px;
    border: 0;
    border-radius: 5px;
  }
  
  .langDbBtn-11 .language-dropdown .dropdown-toggle:hover,
  .langDbBtnSidebar-11 .language-dropdown .dropdown-toggle:hover {
    background: #000;
    color: #fff;
  }

  .langDbBtn-11 .language-dropdown .dropdown-toggle:focus,
  .langDbBtnSidebar-11 .language-dropdown .dropdown-toggle:focus {
    box-shadow: unset;
  }
  
  @media (max-width: 991px) {
    .langDbBtn-11 .language-dropdown .dropdown-toggle {
      padding: 10px 20px;
      font-size: 12px;
    }
  }
  
  @media (max-width: 767px) {
    .langDbBtnSidebar-11 {
      display: block;
    }
  
    .langDbBtn-11 {
      display: none;
    }
  
    .langDbBtnSidebar-11 {
      margin-top: 10px;
    }
  
    .langDbBtnSidebar-11 .language-dropdown .dropdown-toggle {
      padding: 10.5px 30px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 575px) {
    .header-links .bookNowButton {
      padding: 8px 18px;
      font-size: 10px;
    }
  }
  
  .skiptranslate{
   display: none;
  }
  body {
    top: 0 !important;
  }